import { useEffect, useMemo, useState } from 'react';
import { useInput, ImageInputProps, Labeled, useTranslate, useRecordContext } from 'react-admin';
import { Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  padding: 20,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

type Media = string;

export default function CustomFileInput(props: ImageInputProps) {
  const { source, label, accept, resource } = props;
  const {
    field: { name, onChange, value },
    fieldState: { isTouched, error },
    isRequired,
  } = useInput(props);
  const record = useRecordContext();
  const thumbOrFile = record?.pdf_thumb || record?.pdf || value;

  const [preview, setPreview] = useState<Media>();
  const [newFile, setNewFile] = useState<File>();

  useEffect(() => {
    setPreview(thumbOrFile);
  }, [thumbOrFile]);

  const translate = useTranslate();
  const i18nLabel = translate(`resources.${resource}.fields.${label || source}`);

  const onDropAccepted = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setNewFile(file);
    onChange(file);
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept, onDropAccepted });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ]);

  const handleDelete = () => {
    onChange(null);
  };

  return (
    <Labeled label={i18nLabel} fullWidth={true} isRequired={isRequired}>
      <div className="container" style={{ margin: '10px 5px' }}>
        <div>{i18nLabel}</div>
        <div {...getRootProps({ style })}>
          <input {...getInputProps({ name })} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        {newFile || preview
          ? (
            <div
              style={{
                padding: '5px 10px',
                margin: 5,
                minWidth: 100,
                height: 100,
                borderWidth: 2,
                borderColor: '#efefef',
                borderStyle: 'solid',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pointerEvents: newFile ? 'none' : 'initial',
                position: 'relative',
              }}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href={preview}
                style={{
                  textDecoration: 'none',
                }}
              >{newFile?.name || preview}</a>
              <button
                onClick={handleDelete}
                style={{
                  position: 'absolute',
                  right: 20,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  borderRadius: 5,
                  width: 50,
                  height: 50,
                  fontSize: 18,
                  backgroundColor: 'transparent',
                }}
              >&#10006;</button>
            </div>
          )
          : null}

        {isRequired && isTouched && error?.message ? (
          <Typography
            aria-errormessage={translate(error?.message)}
            variant="body2"
            color="error"
            gutterBottom
            paragraph
            align="center"
          >
            {translate(error?.message)}
          </Typography>
        ) : null}
      </div>
    </Labeled>
  );
}
