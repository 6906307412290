import { FC } from 'react';
import {
  TextInput,
  BooleanInput,
  Create,
  SimpleForm,
  CreateProps,
  required,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  RaRecord,
} from 'react-admin';
import CustomImageInput from '../components/CustomImageInput';
import CustomFileInput from '../components/CustomFileInput';
import { slugifyAr } from '../utils/strings';
import ChapterReorder from '../components/ChapterReorder';
import { sanitizeFormData } from '../utils/form';

const BookCreate: FC<CreateProps> = () => {
  const transform = (data: RaRecord): RaRecord | Promise<RaRecord> => {
    const sanitized = sanitizeFormData(data);
    return {
      ...sanitized,
      slug: slugifyAr(sanitized.name),
    };
  };

  return (
    <Create transform={transform} redirect="edit">
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput
          name="name"
          source="name"
          validate={required()}
          fullWidth={true}
        />

        <BooleanInput
          name="chapterized"
          source="chapterized"
          validate={required()}
          defaultValue={false}
          disabled={false}
          options={{}}
        />
        <BooleanInput
          name="published"
          source="published"
          validate={required()}
          defaultValue={true}
          options={{}}
        />

        <CustomImageInput
          name="image"
          source="image"
          label="image"
          accept="image/jpeg,image/png,image/jpg"
          resource="books"
        />

        <CustomFileInput
          name="pdf"
          source="pdf"
          label="pdf"
          accept="application/pdf"
          resource="books"
        />

        <ReferenceArrayInput
          name="category_ids"
          reference="categories"
          source="category_ids"
          enableGetChoices={({ search }) => (search ? search.length >= 2 : false)}
          filter={{ fields: ['id', 'name'], withoutRelations: true }}
        >
          <AutocompleteArrayInput
            name="category_ids"
            label="resources.books.fields.categories"
            shouldRenderSuggestions={(value: string) => value.length >= 2}
            noOptionsText="start typing to display options"
            filterToQuery={(search: string) => ({ search })}
            fullWidth
            optionText="name"
            optionValue="id"
          />
        </ReferenceArrayInput>

        <ReferenceArrayInput
          name="chapter_ids"
          reference="chapters"
          source="chapter_ids"
          enableGetChoices={({ search }) => (search ? search.length >= 2 : false)}
          filter={{ fields: ['id', 'name'], withoutRelations: true, book_id: null }}
        >
          <AutocompleteArrayInput
            name="chapter_ids"
            label="resources.books.fields.chapters"
            shouldRenderSuggestions={(value: string) => value.length >= 2}
            noOptionsText="start typing to display options"
            filterToQuery={(search: string) => ({ search })}
            fullWidth
            optionText="name"
            optionValue="id"
          />
        </ReferenceArrayInput>

        <ReferenceArrayInput
          name="chapters"
          reference="chapters"
          source="chapter_ids"
          enableGetChoices={({ search }) => (search ? search.length >= 2 : false)}
          filter={{ fields: ['id', 'name'], withoutRelations: true, book_id: null }}
        >
          <ChapterReorder
            name="chapter_ids"
            source="chapter_ids"
            label="chapters order"
            resource="books"
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default BookCreate;
