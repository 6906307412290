import { FC } from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';

const Dashboard: FC = () => {

  return (
    <Card>
      <CardHeader title="Welcome to the administration" />
      <CardContent>Dashboard summary</CardContent>
    </Card>
  );
};

export default Dashboard;
