import list from './list';
import create from './create';
import edit from './edit';

import CategoryIcon from '@material-ui/icons/Category';

const categories = {
  list,
  edit,
  create,
  icon: CategoryIcon,
  name: 'categories',
}

export default categories;
