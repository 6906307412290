import Axios, { AxiosRequestConfig } from 'axios';

const { REACT_APP_API_BASE_URL, REACT_APP_API_CRUD_PATH } = process.env;
const baseURL = `${REACT_APP_API_BASE_URL}${REACT_APP_API_CRUD_PATH}`;

const axios = Axios.create({
    baseURL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    if (token && config.headers) config.headers.Authorization = token;
    return config;
});

export default axios;
