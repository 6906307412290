import { FC } from 'react';
import {
  TextInput,
  Create,
  SimpleForm,
  CreateProps,
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import CustomImageInput from '../components/CustomImageInput';
import { RaRecord } from 'ra-core';
import { slugifyAr } from '../utils/strings';
import { Book, Category } from '../types';

const CategoryCreate: FC<CreateProps> = (_: CreateProps<Category>) => {
  const transform = (data: RaRecord): RaRecord | Promise<RaRecord> => {
    const { name, description, image } = data;
    const bookIds = data.books?.map((v: Book) => v.id);
    return {
      name,
      slug: slugifyAr(data.name),
      ...(description && { description }),
      ...(image && { image }),
      ...(bookIds?.length && { books: bookIds }),
    };
  };

  return (
    <Create transform={transform}>
      <SimpleForm
        redirect="edit"
        warnWhenUnsavedChanges
      >
        <TextInput
          name="name"
          source="name"
          validate={required()}
          fullWidth={true}
        />

        <CustomImageInput
          name="image"
          source="image"
          resource="categories"
          label="image"
          accept="image/jpeg,image/png,image/jpg"
        />

        <TextInput
          name="description"
          source="description"
          fullWidth={true}
          multiline={true}
        />

        <ReferenceArrayInput
          name="books"
          reference="books"
          source="books"
          enableGetChoices={({ search }) => (search ? search.length >= 2 : false)}
          filter={{ fields: ['id', 'name'], withoutRelations: true }}
        >
          <AutocompleteArrayInput
            name="books"
            label="resources.categories.fields.books"
            fullWidth
            filterToQuery={(search: string) => ({ search })}
            shouldRenderSuggestions={(value: string) => value.length >= 2}
            noOptionsText="start typing to display options"
            optionText="name"
            optionValue="id"
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default CategoryCreate;
