import { AxiosError } from 'axios';
import axios from './lib/axios';
import { AuthProvider } from 'react-admin';

const authProvider: AuthProvider = {
  // called when the user attempts to log in
  login: async ({ username: email, password }) => {
    try {
      // call the backend route /login
      await axios.post('/login', { email, password });
      const { data } = await axios.post('/tokens/create');
      if (data['token']) {
        localStorage.setItem('token', data['token']);
        return Promise.resolve();
      }
      return Promise.reject({ message: 'token not found' });
    } catch (e) {
      console.error('Error logging in', e);
      return Promise.reject(e);
    }
  },
  // called when the user clicks on the logout button
  logout: async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return Promise.resolve();
      // call the backend route /tokens/delete
      await axios.post('/tokens/delete');
      // then call the backend route /logout
      await axios.post('/logout');
      // then delete the token
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return Promise.resolve();
    } catch (e) {
      console.error('Error logging out', e);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return Promise.reject({ ...e as AxiosError, redirectTo: '/login', message: 'ra.notification.login_required' });
    }
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return Promise.reject({ message: 'ra.notification.login_required', redirectTo: '/login' });
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    // 1. Check if there's a token
    if (!localStorage.getItem('token')) {
      return Promise.reject({
        message: 'ra.notification.login_required',
        redirectTo: '/login',
      });
    }

    try {
      // 2. Check if /user responds successfully
      const { data } = await axios.get('/user');
      localStorage.setItem('user', JSON.stringify(data));
      // if returns successful response resolve
      return Promise.resolve();
    } catch (e) {
      // if returns failed response reject
      console.error('Unauthenticated', e);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return Promise.reject({ ...e as AxiosError, redirectTo: '/login', message: 'ra.notification.login_required' });
    }
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
  getIdentity: () => {
    try {
      const user = localStorage.getItem('user');
      const { id, name: fullName } = user ? JSON.parse(user) : {} as { id: string, name: string };
      return Promise.resolve({ id, fullName });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default authProvider;
