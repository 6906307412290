import { FC } from 'react';
import { useRecordContext, FieldProps, useTranslate } from 'react-admin';

const ChaptersField: FC<FieldProps> = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  const chapterized = record['chapterized'];

  return (
    <div>
      <div>{chapterized ? translate('custom.chapterized') : translate('custom.not_chapterized')}</div>
      <div>{record['chapters_count'] ? record['chapters_count'] : 0}</div>
    </div>
  );
};

export default ChaptersField;
