import { Fragment } from 'react';
import { BulkActionProps, BulkDeleteButton, BulkExportButton } from 'react-admin';

const BulkActionButtons = (_: BulkActionProps) => (
  <Fragment>
    <BulkExportButton />
    <BulkDeleteButton />
  </Fragment>
);

export default BulkActionButtons;
