import list from './list';
import create from './create';
import edit from './edit';

import MenuBookIcon from '@material-ui/icons/MenuBook';

const books = {
  list,
  edit,
  create,
  icon: MenuBookIcon,
  name: 'books',
}

export default books;
