import { FC } from 'react';
import {
  TextInput,
  Edit,
  SimpleForm,
  EditProps,
  required,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { yupResolver } from '@hookform/resolvers/yup';
import validateChapterCreateEdit from './validate';
import CustomFileInput from '../components/CustomFileInput';

const ChapterEdit: FC<EditProps> = () => {
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm
        redirect="false"
        warnWhenUnsavedChanges
        resolver={yupResolver(validateChapterCreateEdit)}
      >
        <TextInput
          name="name"
          source="name"
          validate={required()}
          fullWidth={true}
        />

        <TextInput
          name="slug"
          source="slug"
          validate={required()}
          disabled={true}
        />

        <CustomFileInput
          name="pdf"
          source="pdf"
          resource="chapters"
          label="pdf"
          accept="application/pdf"
        />

        <RichTextInput
          name="content"
          source="content"
          label="resources.chapters.fields.content"
          fullWidth={true}
        />

        <ReferenceInput
          name="books"
          reference="books"
          source="book_id"
          enableGetChoices={({ search }) => (search ? search.length >= 2 : false)}
          filter={{ fields: ['id', 'name'], withoutRelations: true }}
        >
          <AutocompleteInput
            name="book_id"
            label="resources.chapters.fields.books"
            shouldRenderSuggestions={(value: string) => value.length >= 2}
            noOptionsText="start typing to display options"
            filterToQuery={(search: string) => ({ search })}
            fullWidth
            optionText="name"
            optionValue="id"
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default ChapterEdit;
