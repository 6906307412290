export type PreviewFile = {
  preview: string;
} & File;

type Image = string;

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row' as 'row',
  flexWrap: 'wrap' as 'wrap',
  marginTop: 16,
  borderBottom: '3px solid #eaeaea',
  justifyContent: 'center',
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 300,
  height: 300,
  padding: 4,
  boxSizing: 'border-box' as 'border-box',
  justifyContent: 'center',
};

const thumbInner = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 0,
  overflow: 'hidden',
  width: '100%',
  height: '100%',
};

const image = {
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
};

export function ImageFilePreview({ files }: { files?: PreviewFile[] }) {
  const thumbs = files?.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={image}
          alt={file.name}
          key={file.name}
        />
      </div>
    </div>
  ));

  return (
    <aside style={thumbsContainer} key="file">
      {thumbs}
    </aside>
  );
}

export function ImageUrlPreview({ images }: { images?: Image[] }) {
  const thumbs = images?.map(img => (
    <div style={thumb} key={img}>
      <div style={thumbInner}>
        <img
          src={img}
          style={image}
          alt={img}
          key={img}
        />
      </div>
    </div>
  ));

  return (
    <aside style={thumbsContainer} key="image">
      {thumbs}
    </aside>
  );
}
