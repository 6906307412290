import { FC } from 'react';
import {
  List,
  Datagrid,
  TextField,
  SimpleList,
  EditButton,
  ListProps,
  DeleteButton,
  TextInput,
  SelectInput,
  ReferenceInput,
} from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import MyUrlField from '../components/MyUrlField';

const chaptersFilters = [
  <TextInput label="ra.action.search" source="search" alwaysOn resettable={true} name="search" />,
  <ReferenceInput
    name="book_id"
    label="resources.books.fields.name"
    source="book_id"
    reference="books"
    filter={{ fields: ['id', 'name'], withoutRelations: true }}
    optionText="name"
    allowEmpty
    emptyValue={null}
  >
    <SelectInput optionText="name" label="resources.books.fields.name" source="book_id" name="book_id" />
  </ReferenceInput>,
];

const ChapterList: FC<ListProps> = () => {
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  return (
    <List
      filters={chaptersFilters}
      // filterDefaultValues={{}}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record => `${record.slug}`}
          tertiaryText={record => new Date(record.updated_at).toLocaleDateString()}
        />
      ) : (
        <Datagrid /*rowClick="edit"*/>
          <TextField source="name" />
          <TextField source="book.name" />
          <MyUrlField source="pdf" />

          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

export default ChapterList;
