import { stringify } from 'query-string';
import axios from './lib/axios';
import { DataProvider } from 'react-admin';
import { getDiffData, getFormData } from './utils/form';

const dataProvider: DataProvider = {
  getList: (resource, params) => {
    const { sort: { field, order }, pagination, filter } = params;

    return axios
      .get(`/${resource}`, {
        params: {
          sort: { field, order: order.toLowerCase() },
          ...(Object.keys(filter).length ? { filter } : {}),
          pagination,
        },
      })
      .then(({ data: { data, total } }) => {
        return ({ data, total });
      });
  },

  getOne: (resource, params) =>
    axios
      .get(`/${resource}/${params.id}`)
      .then(({ data: { data } }) => {
        return { data }
      }),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids, fields: ['id', 'name'], withoutRelations: true }),
    };
    const url = `/${resource}?${stringify(query)}`;
    return axios
      .get(url)
      .then(({ data: { data } }) => ({ data }));
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };

    const url = `/${resource}?${stringify(query)}`;
    const { data: { data, total } } = await axios.get(url);
    return { data, total };
  },

  update: (resource, params) => {
    const { data, previousData, id } = params;

    return axios({
      method: 'POST',
      url: `/${resource}/${id}`,
      data: getFormData(getDiffData(data, previousData), true),
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then(({ data: { data }}) => ({ data }));
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return axios
      .put(`/${resource}?${stringify(query)}`, {
        body: JSON.stringify(params.data),
      }).then(({ data: { data } }) => ({ data }));
  },

  create: (resource, params) => {
    return axios({
      method: 'POST',
      url: `/${resource}`,
      data: getFormData(params.data),
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then(({ data: { data } }) => ({ data: { ...params.data, id: data.id } }));
  },

  delete: (resource, params) =>
    axios
      .delete(`/${resource}/${params.id}`)
      .then(({ data }) => ({ data })),

  deleteMany: (resource, params) => {
    return axios
      .delete(`/${resource}`, {
        params: {
          ids: params.ids
        },
      })
      .then(() => ({ data: params.ids }));
  },
};

export default dataProvider;
