import {
  useInput,
  TextInputProps,
  Labeled,
  useTranslate,
  useChoicesContext,
} from 'react-admin';
import { List, arrayMove, RenderListParams, OnChangeMeta, RenderItemParams } from 'react-movable';

export default function ChapterReorder(props: TextInputProps) {
  const { allChoices: chapters } = useChoicesContext();
  const { source, label, resource, disabled } = props;
  const {
    field,
    isRequired,
  } = useInput(props);
  const { onChange, value: chapterIds } = field;

  const translate = useTranslate();
  const i18nLabel = translate(`resources.${resource}.fields.${label || source}`);

  const onSortingChanged = ({ oldIndex, newIndex }: OnChangeMeta) => {
    onChange(arrayMove<number>(chapterIds, oldIndex, newIndex));
  };

  if (!chapterIds) {
    return null;
  }

  return (
    <Labeled label={i18nLabel} fullWidth={true} isRequired={isRequired}>
      <div
        style={{
          maxWidth: '500px',
          margin: '0px auto',
          backgroundColor: '#F7F7F7',
          padding: '3em',
        }}
      >
        {chapterIds && (
          <List
            lockVertically
            values={chapterIds}
            onChange={onSortingChanged}
            renderList={({ children, props, isDragged }: RenderListParams) => (
              <ul
                {...props}
                style={{ padding: 0, cursor: isDragged ? 'grabbing' : undefined }}
              >{children}</ul>
            )}
            renderItem={({ value, props, isDragged, isSelected, index }: RenderItemParams<number>) => (
              <li
                {...props}
                style={{
                  ...props.style,
                  padding: '1.5em',
                  margin: '0.5em 0em',
                  listStyleType: 'none',
                  cursor: isDragged
                    ? 'grabbing'
                    : disabled
                      ? 'default'
                      : 'grab',
                  border: '2px solid #CCC',
                  boxShadow: '3px 3px #AAA',
                  color: disabled ? '#888' : '#333',
                  borderRadius: '5px',
                  outline: disabled ? 'none' : undefined,
                  backgroundColor:
                    isDragged || isSelected
                      ? '#EEE'
                      : disabled
                        ? '#EEE'
                        : '#FFF',
                  zIndex: 1,
                }}
              >
                {(index as number) + 1} - {chapters?.find(v => v.id === value)?.name}
              </li>
            )}
          />
        )}
      </div>
    </Labeled>
  );
}
