import { useMemo, useState } from 'react';
import { useInput, ImageInputProps, Labeled, useTranslate, useRecordContext } from 'react-admin';
import { Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { ImageFilePreview, ImageUrlPreview, PreviewFile } from './ImagePreview';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  padding: 20,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function CustomImageInput(props: ImageInputProps) {
  const { source, label, accept, resource } = props;
  const {
    field: { name, onChange, value },
    fieldState: { isTouched, error },
    isRequired,
  } = useInput(props);
  const record = useRecordContext();
  const thumbOrImage = record?.image_thumb || record?.image || value;

  const [previews, setPreviews] = useState<PreviewFile[]>();

  const translate = useTranslate();
  const i18nLabel = translate(`resources.${resource}.fields.${label || source}`);

  const onDropAccepted = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setPreviews([Object.assign(file, {
      preview: URL.createObjectURL(file)
    })]);
    onChange(file);
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept, onDropAccepted });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ]);

  return (
    <Labeled label={i18nLabel} fullWidth={true} isRequired={isRequired}>
      <div className="container" style={{ margin: '10px 5px' }}>
        <div>{i18nLabel}</div>
        <div {...getRootProps({ style })}>
          <input {...getInputProps({ name })} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        {previews?.length ? <ImageFilePreview files={previews} /> : null}
        {thumbOrImage && !previews?.length ? <ImageUrlPreview images={[thumbOrImage]} /> : null}

        {isRequired && isTouched && error?.message ? (
          <Typography
            aria-errormessage={translate(error?.message)}
            variant="body2"
            color="error"
            gutterBottom
            paragraph
            align="center"
          >
            {translate(error?.message)}
          </Typography>
        ) : null}
      </div>
    </Labeled>
  );
}
