import { FC } from 'react';
import {
  TextInput,
  Create,
  SimpleForm,
  CreateProps,
  required,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { yupResolver } from '@hookform/resolvers/yup';
import validateChapterCreateEdit from './validate';
import { slugifyAr } from '../utils/strings';
import CustomFileInput from '../components/CustomFileInput';
import { RaRecord } from 'ra-core';
import { sanitizeFormData } from '../utils/form';

const ChapterCreate: FC<CreateProps> = () => {
  const transform = (data: RaRecord): RaRecord | Promise<RaRecord> => {
    const sanitized = sanitizeFormData(data);
    return {
      ...sanitized,
      slug: slugifyAr(sanitized.name),
    };
  };

  return (
    <Create transform={transform}>
      <SimpleForm
        redirect="edit"
        warnWhenUnsavedChanges
        resolver={yupResolver(validateChapterCreateEdit)}
      >
        <TextInput
          name="name"
          source="name"
          validate={required()}
          fullWidth={true}
        />

        <CustomFileInput
          name="pdf"
          source="pdf"
          resource="chapters"
          label="pdf"
          accept="application/pdf"
        />

        <RichTextInput
          name="content"
          source="content"
          label="resources.chapters.fields.content"
          fullWidth={true}
        />

        <ReferenceInput
          name="books"
          reference="books"
          source="book_id"
          enableGetChoices={({ search }) => (search ? search.length >= 2 : false)}
          filter={{ fields: ['id', 'name'], withoutRelations: true }}
        >
          <AutocompleteInput
            name="book_id"
            label="resources.chapters.fields.books"
            shouldRenderSuggestions={(value: string) => value.length >= 2}
            noOptionsText="start typing to display options"
            filterToQuery={(search: string) => ({ search })}
            fullWidth
            optionText="name"
            optionValue="id"
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default ChapterCreate;
