import * as yup from 'yup';

const schema = yup.object({
    content: yup.string(),
    pdf: yup.string().when('content', (field, schema) => {
        return !field ? schema.required('The content field is required if pdf is empty') : schema.notRequired();
    }),
}).required();

export default schema;

export const validateChapterCreateEdit = (values: { content: string; pdf: string; }) => {
    const errors = {} as { content: string; pdf: string; };

    if (!values.content && !values.pdf) {
        errors.content = 'The content field is required if pdf is empty';
        errors.pdf = 'The pdf field is required if content is empty';
    }

    return errors;
};
