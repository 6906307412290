import { FC } from 'react';
import {
  TextInput,
  Edit,
  SimpleForm,
  EditProps,
  required,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from 'react-admin';
import CustomImageInput from '../components/CustomImageInput';

const CategoryEdit: FC<EditProps> = () => {
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm
        redirect="false"
        warnWhenUnsavedChanges
      >
        <TextInput
          name="name"
          source="name"
          validate={required()}
          fullWidth={true}
        />

        <TextInput
          name="slug"
          source="slug"
          validate={required()}
          disabled={true}
        />

        <CustomImageInput
          name="image"
          source="image"
          resource="categories"
          label="image"
          accept="image/jpeg,image/png,image/jpg"
        />

        <TextInput
          name="description"
          source="description"
          fullWidth={true}
          multiline={true}
        />

        <ReferenceArrayInput
          name="books"
          reference="books"
          source="book_ids"
          enableGetChoices={({ search }) => (search ? search.length >= 2 : false)}
          filter={{ fields: ['id', 'name'], withoutRelations: true }}
        >
          <AutocompleteArrayInput
            name="book_ids"
            label="resources.categories.fields.books"
            fullWidth
            filterToQuery={(search: string) => ({ search })}
            shouldRenderSuggestions={(value: string) => value.length >= 2}
            noOptionsText="start typing to display options"
            optionText="name"
            optionValue="id"
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default CategoryEdit;
