import list from './list';
import create from './create';
import edit from './edit';

import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

const chapters = {
    list,
    edit,
    create,
    icon: LibraryBooksIcon,
    name: 'chapters',
}

export default chapters;
