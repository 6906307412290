import { TranslationMessages } from 'ra-core';

export const arabicMessages: TranslationMessages = {
  ra: {
    action: {
      add_filter: 'إضافة فلتر',
      add: 'إضافة',
      back: 'العودة',
      bulk_actions: '%{smart_count} العناصر المحددة',
      cancel: 'إلغاء',
      clear_input_value: 'إفراغ المدخلات',
      clone: 'استنساخ',
      confirm: 'تأكيد',
      create: 'إنشاء',
      delete: 'حذف',
      edit: 'تعديل',
      export: 'تصدير',
      list: 'قائمة',
      refresh: 'إعادة تحميل',
      remove_filter: 'إزالة هذا الفلتر',
      remove: 'إزالة',
      save: 'حفظ',
      search: 'بحث',
      show: 'عرض التفاصيل',
      sort: 'فرز',
      undo: 'تراجع',
      unselect: 'الغاء التحديد',
      expand: 'فرد',
      close: 'اغلاق',
      open_menu: 'افتح القائمة',
      close_menu: 'اغلق القائمة',
      create_item: 'إنشاء العنصر',
      move_up: 'حرك للآعلى',
      move_down: 'حرك للآسفل',
      select_all: 'اختر جميعهم',
      select_row: 'اختر صف',
      update: 'تحديث',
      open: '',
      toggle_theme: '',
      remove_all_filters: '',
    },
    boolean: {
      true: 'نعم',
      false: 'لا',
      null: '',
    },
    page: {
      create: 'إنشاء %{name}',
      dashboard: 'لوحة الإحصائيات',
      edit: '%{name} #%{id}',
      error: 'هناك خطأ ما',
      list: '%{name}',
      loading: 'جار التحميل',
      not_found: 'غير موجود',
      show: '%{name} #%{id}',
      empty: 'لا يوجد %{name} حتي الان',
      invite: 'هل ترغب فى اضافة واحد؟',
    },
    input: {
      file: {
        upload_several: 'إسقاط بعض الملفات للتحميل، أو انقر لتحديد واحد.',
        upload_single: 'إسقاط ملف للتحميل، أو انقر لتحديده.',
      },
      image: {
        upload_several: 'قم بإسقاط بعض الصور للتحميل، أو انقر لتحديد واحدة.',
        upload_single: 'إسقاط صورة للتحميل، أو انقر لتحديدها.',
      },
      references: {
        all_missing: 'غير قادر على العثور على بيانات المراجع.',
        many_missing: 'واحد على الأقل من المراجع المرتبطة لم تعد متوفرة.',
        single_missing: 'المرجع المرتبط لم يعد يبدو متاحًا.',
      },
      password: {
        toggle_visible: 'اخفاء الرقم السري',
        toggle_hidden: 'اظهار الرقم السري',
      },
    },
    message: {
      about: 'حول',
      are_you_sure: 'هل أنت واثق؟',
      bulk_delete_content:
        'هل أنت متأكد أنك تريد حذف هذا %{name}? |||| هل أنت متأكد من أنك تريد حذف هذه العناصر%{smart_count}?',
      bulk_delete_title: 'حذف %{name} |||| احذف عناصر%{smart_count}%{name}',
      delete_content: 'هل أنت متأكد أنك تريد حذف هذا البند؟',
      delete_title: 'حذف %{name} #%{id}',
      details: 'تفاصيل',
      error: 'حدث خطأ في التطبيق ولم يمكن إكمال طلبك.',
      invalid_form: 'النموذج غير صالح. يرجى التحقق من وجود أخطاء',
      loading: 'يتم تحميل الصفحة، فقط لحظة من فضلك',
      no: 'لا',
      not_found: 'الصفحة غير موجودة',
      yes: 'نعم ',
      unsaved_changes:
        'لم يتم حفظ بعض تغييراتك. هل أنت متأكد أنك تريد تجاهلها؟',
      bulk_update_content: 'تحديث المحتوى بالجملة',
      bulk_update_title: 'تحديث العنوان بالجملة',
    },
    navigation: {
      no_results: 'لا توجد نتائج',
      no_more_results: 'رقم الصفحة%{page} خارج الحدود. جرب الصفحة السابقة.',
      page_out_of_boundaries: 'رقم الصفحة%{page} خارج الحدود',
      page_out_from_end: 'لا يمكن الذهاب بعد الصفحة الأخيرة',
      page_out_from_begin: 'لا يمكن الذهاب قبل الصفحة الأولى',
      page_range_info: '%{offsetBegin}-%{offsetEnd} من %{total}',
      page_rows_per_page: 'الصفوف لكل صفحة:',
      next: 'التالى',
      previous: 'السابق',
      skip_nav: 'تخطي الى المحتوي',
      page: '',
      first: '',
      last: '',
      current_page: '',
      partial_page_range_info: '',
    },
    sort: {
      sort_by: 'رتب حسب %{field} %{order}',
      ASC: 'تصاعدي',
      DESC: 'تنازلي',
    },
    auth: {
      auth_check_error: 'الرجاء تسجيل الدخول للاستمرار',
      user_menu: 'الملف الشخصي',
      username: 'اسم المستخدم',
      password: 'كلمة السر',
      sign_in: 'تسجيل الدخول',
      sign_in_error: 'أخفقت المصادقة، يرجى إعادة المحاولة',
      logout: 'الخروج',
    },
    notification: {
      updated: 'تم تحديث العنصر |||| تم تحديث%{smart_count} من العناصر',
      created: 'تم إنشاء العنصر',
      deleted: 'تم حذف العنصر |||| تم حذف%{smart_count} من العناصر',
      bad_item: 'عنصر غير صحيح',
      item_doesnt_exist: 'العنصر غير موجود',
      http_error: 'خطأ في اتصال الخادم',
      i18n_error: 'لا يمكن تحميل الترجمة لهذه اللغة',
      data_provider_error:
        'خطأ في مزود البيانات. تحقق من وحدة التحكم للحصول على التفاصيل.',
      canceled: 'تم إلغاء الإجراء',
      logged_out: 'انتهت جلستك، يرجى إعادة الاتصال.',
      not_authorized: 'غير مخول',
      login_required: 'يجب تسجيل الدخول',
    },
    validation: {
      required: 'مطلوب',
      minLength: 'يجب أن يكون%{min} حرفًا على الأقل',
      maxLength: 'يجب أن يكون%{max} حرفًا أو أقل',
      minValue: 'يجب أن يكون%{min} على الأقل',
      maxValue: 'يجب أن يكون%{max} أو أقل',
      number: 'يجب أن يكون رقما',
      email: 'يجب أن يكون بريدًا إلكترونيًا صالحًا',
      oneOf: 'يجب أن يكون واحدًا من:%{options}',
      regex: 'يجب أن يتطابق مع تنسيق محدد (regex):%{pattern}',
    },
    saved_queries: {
      label: '',
      help: '',
      query_name: '',
      new_label: '',
      remove_message: '',
      new_dialog_title: '',
      remove_label: '',
      remove_label_with_name: '',
      remove_dialog_title: '',
    },
  },
  custom: {
    chapterized: 'مجزأ',
    not_chapterized: 'غير مجزأ',
    not_available: 'لا يوجد',
  },
  resources: {
    books: {
      name: 'كتب |||| كتاب',
      fields: {
        name: 'عنوان الكتاب',
        chapterized: 'هل الكتاب مجزأ آم عبارة عن جزء واحد',
        published: 'منشور',
        image: 'غلاف الكتاب',
        pdf: 'PDF الكتاب',
        chapters: 'فصول الكتاب',
        categories: 'تصنيفات هذا الكتاب',
        categories_count: 'عدد تصنيفات هذا الكتاب',
        'chapters order': 'ترتيب فصول الكتاب (من الاول الى الاخر)',
        slug: 'العنوان الشبكي',
      },
    },
    chapters: {
      name: 'فصول |||| فصل',
      fields: {
        name: 'عنوان الفصل',
        'book.name': 'عنوان الكتاب الـذي ينتمي له هذا الجزء',
        pdf: 'PDF الفصل',
        image: 'غلاف الكتاب',
        content: 'محتوى الفصل',
        slug: 'العنوان الشبكي',
        'chapters order': 'ترتيب فصول الكتاب (من الاول الى الاخر)',
      },
    },
    categories: {
      name: 'تصنيفات |||| تصنيف',
      fields: {
        name: 'اسم التصنيف',
        image: 'صورة التصنيف',
        description: 'ملخص آو وصف التصنيف',
        books: 'الكتب المنتمية للتصنيف',
        books_count: 'عدد الكتب المنتميين',
        slug: 'العنوان الشبكي',
      },
    },
  },
};

export default arabicMessages;
