import { RaRecord } from 'react-admin';

export function getDiffData(data: Partial<RaRecord>, previousData: RaRecord) {
  const dataToSubmit: Partial<RaRecord> = {};

  for (const key in data) {
    const previousValue = previousData[key];
    const newValue = data[key];

    const previousValueStringified = JSON.stringify(previousValue);
    const newValueStringified = JSON.stringify(newValue);

    if (previousValueStringified !== newValueStringified) {
      dataToSubmit[key] = newValue;
    }
  }

  return dataToSubmit;
}

export function getFormData(data: Partial<RaRecord>, update: boolean = false) {
  const formData = new FormData();

  if (update) {
    formData.append('_method', 'PATCH');
  }

  for (const key in data) {
    const value = data[key];
    if (Array.isArray(value)) {
      formData.append(key, JSON.stringify(value));
    } else if (typeof value === 'boolean') {
      formData.append(key, Number(value).toString());
    } else {
      formData.append(key, value);
    }
  }

  return formData;
}

export function sanitizeFormData(data: RaRecord): RaRecord {
  const sanitizedData: Partial<RaRecord> = {};
  for (const key in data) {
    if (typeof data[key] === 'string' && data[key].length === 0) continue;
    if (typeof data[key] === 'undefined') continue;
    sanitizedData[key] = data[key];
  }
  return sanitizedData as RaRecord;
}
