import { useEffect } from 'react';
import axios from './lib/axios';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { createTheme, ThemeProvider, StylesProvider, jssPreset } from '@material-ui/core/styles';
import { arabicMessages } from './lib/translation';

import Dashboard from './Dashboard';
import authProvider from './authProvider';
import dataProvider from './dataProvider';

import books from './books';
import chapters from './chapters';
import categories from './categories';

import { create } from 'jss';
import rtl from 'jss-rtl';

// @ts-ignore
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const i18nProvider = polyglotI18nProvider(() => arabicMessages, 'ar');

const theme = createTheme({
  direction: 'rtl',
});

const App = () => {

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + '/sanctum/csrf-cookie')
      .then()
      .catch(e => console.error('Error setting csrf cookie', e));
  }, []);

  return (
    // @ts-ignore
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <Admin
          theme={theme}
          i18nProvider={i18nProvider}
          dashboard={Dashboard}
          authProvider={authProvider}
          dataProvider={dataProvider}
          disableTelemetry
        >
          <Resource {...books} />
          <Resource {...chapters} />
          <Resource {...categories} />
        </Admin>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
