import { FC } from 'react';
import { useRecordContext, FieldProps, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    width: '100px',
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  icon: {
    width: '0.5em',
    height: '0.5em',
    paddingLeft: 2,
  },
});

const MyUrlField: FC<FieldProps> = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  const translate = useTranslate();

  return source && record && record[source] ? (
    <a href={record[source]} className={classes.link} target="_blank" rel="noreferrer">
      PDF
      <LaunchIcon className={classes.icon} />
    </a>
  ) : (
    <div>{translate('custom.not_available')}</div>
  );
}

export default MyUrlField;
