import { FC } from 'react';
import {
  List,
  Datagrid,
  TextField,
  SimpleList,
  EditButton,
  ListProps,
  TextInput,
  Filter,
  NumberField,
  SingleFieldList,
  ChipField,
  ArrayField,
  DeleteButton,
} from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';

const CategoryList: FC<ListProps> = props => {
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  return (
    <List
      filters={
        <Filter {...props}>
          <TextInput label="ra.action.search" source="search" alwaysOn resettable={true} name="search" />
        </Filter>
      }
      filterDefaultValues={{}}
      sort={{ field: 'name', order: 'ASC' }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record => `${record.slug}`}
          tertiaryText={record => new Date(record.updated_at).toLocaleDateString()}
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="name" />
          <TextField source="slug" />
          <TextField source="description" />
          <ArrayField source="books">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <NumberField source="books_count" />

          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

export default CategoryList;
