import { FC } from 'react';
import {
  List,
  Datagrid,
  TextField,
  SimpleList,
  EditButton,
  ListProps,
  BooleanField,
  NumberField,
  ArrayField,
  ChipField,
  SingleFieldList,
  DeleteButton,
  TextInput,
  BooleanInput,
  ImageField,
} from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import MyUrlField from '../components/MyUrlField';
import ChaptersField from '../components/ChaptersField';
import BulkActionButtons from '../components/BulkActionButtons';

const booksFilters = [
  <TextInput label="ra.action.search" source="search" alwaysOn resettable={true} name="search" />,
  <BooleanInput name="published" source="published"  options={{}} />,
];

const BookList: FC<ListProps> = _ => {
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  return (
    <List
      filters={booksFilters}
      // filterDefaultValues={{ published: true }}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record => `${record.slug}`}
          tertiaryText={record => new Date(record.updated_at).toLocaleDateString()}
        />
      ) : (
        <Datagrid /*rowClick="edit"*/ bulkActionButtons={<BulkActionButtons />}>
          <TextField source="name" />
          <BooleanField source="published" />
          <ChaptersField source="chapters" />
          <ImageField source="image_thumb" title="image" />
          <MyUrlField source="pdf" />
          <ArrayField source="categories">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <NumberField source="categories_count" />

          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

export default BookList;
